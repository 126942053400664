import React from 'react'
import LibraryPosts from '../components/Library/LibraryPosts'

export default function Library() {
  return (
    <>
    <LibraryPosts />
    </>
  )
}
