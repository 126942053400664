import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { posts } from './Posts'

export default function LibraryPosts() {
  return (

    
   <section className='section-library-posts'>
    <Container>
        <Row>
            { posts.map((post) => {
                return<>
                <Col sm={12} className='mt-3 mb-3'>
                    <Container>
                        <Row>
                            <Col>
                            <img className='img-fluid side-img' src={post.banner} alt={post.title} />
                            </Col>
                            <Col>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <Row>
                                            <Col>
                                            <img className='img-fluid profile-img' src={post.profile} alt={post.profile} />
                                            </Col>
                                            <Col>
                                            <Row>
                                                <Col sm={12}>{post.writer}</Col>
                                                <Col sm={12}>{post.publishedDate}</Col>
                                            </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12}>
                                        <h1>{post.title}</h1>

                                        <p>{post.description} ...</p>
                                    </Col>
                                </Row>
                            </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                </>;
            }) }
        </Row>
    </Container>
   </section>
  )
}
