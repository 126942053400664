import React,{useState, useEffect} from 'react'
import { Card, Carousel, Spinner } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from 'axios';
import { companyId, server } from '../../env';
import htmlToDraft from 'html-to-draftjs';

type HomeData = {
  Title: string;
  Points: string;
  Image: string;
};
export default function ClientList() {
  const [datas, setDatas] = useState<HomeData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    axios.get(server + "/api/content/Home/ClientList/" + companyId)
    .then((result) => {
     console.log(result.data.data);
     setDatas(result.data.data as HomeData[]);
     console.log("Converted Value");
     console.log(result.data.data as HomeData[]);
     setLoading(false);
    })
    .catch((error) =>{

    });
  }, []);
  if(loading)
    return (<Spinner animation="grow" variant="primary" />);
  else
  return (
    <section className="section5">
      <h1>OUR CLIENT LIST</h1>
      <h3>
        We are proud to provide our services to various sectors and industries
      </h3>
      <div className="stack">
        <div className="section5-card-green">&nbsp;</div>
        <div className="section5-card-yellow">&nbsp;</div>
        <div className="section5-card-holder">
        <OwlCarousel items={1} frameBorder={0} controls={true} autoplay={true} >
        { datas?.map((item) => {

          return   <div className="o-item">
         
          <div className="card-scroll">
            <Card className="section5-card">
              <Card.Body>
                <div className='img-holder'>
                  <img className='img-fluid' alt={item.Image} src={ server+ "/public/public/Image/" + item.Image } />
                </div>
                <h3>{item.Title}</h3>
                <div className="content" dangerouslySetInnerHTML={{__html: item.Points}}></div>
              </Card.Body>
            </Card>
          </div>
        </div>;
        }) }
        
      
        {/* <div className="o-item">
         
         <div className="card-scroll">
           <Card className="section5-card">
             <Card.Body>
               <h3>Education Sector</h3>
               <ul>
                 <li>Drypers Malaysia</li>
                 <li>Exxon Mobil</li>
               </ul>
             </Card.Body>
           </Card>
         </div>
       </div>
       <div className="o-item">
       
         <div className="card-scroll">
           <Card className="section5-card">
             <Card.Body>
               <h3>Education Sector</h3>
               <ul>
                 <li>Drypers Malaysia</li>
                 <li>Exxon Mobil</li>
               </ul>
             </Card.Body>
           </Card>
         </div>
       </div>
       <div className="o-item">
       
         <div className="card-scroll">
           <Card className="section5-card">
             <Card.Body>
               <h3>Education Sector</h3>
               <ul>
                 <li>Drypers Malaysia</li>
                 <li>Exxon Mobil</li>
               </ul>
             </Card.Body>
           </Card>
         </div>
       </div> */}
          </OwlCarousel>
        </div>        
        
        {/* <Carousel className="section5-card-holder">
          <Carousel.Item interval={1000}>
         
            <div className="card-scroll">
              <Card className="section5-card">
                <Card.Body>
                  <h3>Education Sector</h3>
                  <ul>
                    <li>Drypers Malaysia</li>
                    <li>Exxon Mobil</li>
                  </ul>
                </Card.Body>
              </Card>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={500}>
          
            <div className="card-scroll">
              <Card className="section5-card">
                <Card.Body>
                  <h3>Education Sector</h3>
                  <ul>
                    <li>Drypers Malaysia</li>
                    <li>Exxon Mobil</li>
                  </ul>
                </Card.Body>
              </Card>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          
            <div className="card-scroll">
              <Card className="section5-card">
                <Card.Body>
                  <h3>Education Sector</h3>
                  <ul>
                    <li>Drypers Malaysia</li>
                    <li>Exxon Mobil</li>
                  </ul>
                </Card.Body>
              </Card>
            </div>
          </Carousel.Item>
        </Carousel> */}
      </div>
    </section>
  );
}
